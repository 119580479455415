<template>
    <div class="ai_quest_screen">
        <Navbar />
        <Breadcrumb :breadcrumb="state.listBreadcrumbs"/>
        <div class="container">
            <div class="main-area">
                <div class="content"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/ai-quest/AIQuest.ts">
</script>

<style lang="scss"  src="@/presentation/views/ai-quest/AIQuest.scss" scoped>
</style>